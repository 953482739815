import "./styles.less";

import React, { useContext, useState } from "react";

import { Button, Typography } from "antd";
import { CalendarFilled, ContactsFilled } from "@ant-design/icons";

import { DateRange } from "./DateRange/web";
import { Seasons } from "./Seasons/web";
import { useTemporalFilter } from "./TemporalFilter.hook";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { IC_Collapse } from "shared-components/Components/Collapse/web";


export const TemporalFilter = ({
	filterChanged,
	collapsable = false,
	isInFilters = false,
	showTitle = false
}) => {
	const {
		isTemporal,
		filters: { dateTo, dateFrom, season },
		setShowSeasonInput,
		showSeasonInput
	} = useTemporalFilter();
	const { country_code } = useContext(ConfigStateContext);

	if (!isTemporal) return null;

	const filter = (
		<>
			{showSeasonInput ? (
				<Seasons filterChanged={filterChanged} />
			) : (
				<DateRange filterChanged={filterChanged} />
			)}
			{(isInFilters || country_code !== "ce3") && (
				<Button
					className="btn-search-seasons"
					type="text"
					onClick={() => setShowSeasonInput(!showSeasonInput)}
					icon={showSeasonInput ? <CalendarFilled /> : <ContactsFilled />}
				>
					<Typography.Text>
						{showSeasonInput ? "Buscar por Calendario" : "Buscar por Temporada"}
					</Typography.Text>
				</Button>
			)}
		</>
	);

	const label = "Temporada / Fechas"

	return (
		<div className="filter temporal-filter">
			{collapsable 
				? <IC_Collapse label={label} isActive={dateFrom || dateTo || season}>
					{filter}
				</IC_Collapse>
				: <>
					{showTitle && <h3 className="filter-title">{label}</h3>}
					{filter}
					<hr style={{width:"100%", border:'none', borderTop:'1px solid rgba(0,0,0,.06)', margin:'24px 0'}} />
				</>
			}
		</div>
	);
};
