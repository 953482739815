import React, { useState, useEffect } from 'react';
import './styles.less'

type Props = {
    options: ButtonGroupOptions[],
    selected: any[],
    allowMuliple?: boolean,
    optionForAny?: ButtonGroupOptions,
    onChange?: Function,
    cleanFilter?: Function,
    isSmall?: boolean,
    exactMode?: boolean
}

type ButtonGroupOptions = {
    text: string,
    value: any,
    smallText?: string
}

const ButtonGroup = (props: Props) => {
  const {
    options,
    selected = [],
    allowMuliple = false,
    optionForAny = null,
    onChange = null,
    cleanFilter = null,
    isSmall = false,
    exactMode
  } = props;
  const [values, setValues] = useState(selected);
  const [choices, setChoises] = useState([]);

  useEffect(() => {
      if(optionForAny) {
        setChoises([optionForAny, ...options]);
      } else {
        setChoises(options);
      }
  }, [options]);

  useEffect(() => {
    const selectedValue = Array.isArray(selected) ? selected : [selected]
    setValues(selectedValue)
  }, [selected])

  const handleValueChange = (value) => {
    if(allowMuliple) {
        if(optionForAny && value === optionForAny.value) {
            setValues([optionForAny.value])
            if(cleanFilter) {
                cleanFilter();
            }
        } else {
            if(values.includes(value)) {
                const newValues = [...values];
                newValues.splice(values.indexOf(value), 1);
                if(newValues.length === 0) {
                    setValues([optionForAny?.value]);
                    onChange([optionForAny?.value]);
                }  else {
                    setValues(newValues);
                    onChange(newValues);
                }
            } else {
                const newValues = [...values, value];
                if(optionForAny && values.includes(optionForAny.value)) {
                    newValues.splice(values.indexOf(optionForAny.value), 1);
                }

                setValues(newValues);

                if(onChange) {
                    onChange(newValues);
                }
            }
        }
    } else {
      if(optionForAny && value === optionForAny.value) {
         setValues([optionForAny.value])
         if(onChange) onChange(value)
      } else if(!values.includes(value)) {
        setValues([value]);
        if(onChange) {
          onChange(value);
        }
      }
    }
  };

  const isSelected = (opt, index) => {
    return values.includes(opt.value) || (!values.length && index === 0);
  }

  return (
    <div className="btn-group filter-container">
      <div className={`filter-options ${isSmall ? "filter-options__small " : ""}`}>
        {choices.map((opt,i) => 
          <button
            key={i}
            className={`filter-btn ${isSelected(opt, i) ? 'selected' : ''}`}
            onClick={() => {handleValueChange(opt.value)}}
          >
            {opt.smallText || opt.text}
            {(exactMode  === false && opt.value !== null) ? "+" : ""}
          </button>
        )}
      </div>
    </div>
  );
};

export default ButtonGroup;
