import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { FRAGMENT_PROPERTY_CARD } from "../../Property/PropertyCard/PropertyCard.hook";
import { isValidCharacters } from "../../../Utils/Functions";
import { useDebouncedCallback } from "../../../GlobalHooks/useDebounce";
import { useFilters } from "../Filters.hook";
import { useSearchResultsFilters } from "shared-components/ViewFragments/SearchResults/Sider/Filters/SearchResultsFilters.hook";

const KEYWORD_LOCATION_QUERY = gql`
	query Location($strSearch: String!) {
		searchLocation(searchTerm: $strSearch) {
			... on Estate {
				id
				name
			}
			... on Neighborhood {
				id
				name
				estate {
					id
					name
				}
			}
			__typename
		}
	}
`;

const PROJECTS_COUNT = 3;
const PROJECTS_QUERY = gql`
	query SearchProject($title: String) {
		searchListing(params: { operation_type_id: 1, projects:true, projectTitle: $title }, first: ${PROJECTS_COUNT}, page: 1) {
			data {
				${FRAGMENT_PROPERTY_CARD.query()}
			}
		}
	}
`;

const REF_QUERY = gql`
	query searchByRef($code: String!) {
		searchByRef(refCode: $code) {
			... on Project {
				id
				title
				link
				isEspecial
			}
			... on Property {
				${FRAGMENT_PROPERTY_CARD.query()}
			}
		}
	}
`;

export interface KeywordLocationProps {
	className?: string;
	searchByProject?: boolean;
	searchByReference?: boolean;
	isSearchForHome?: boolean;
	handleLocations?: ([]) => void;
	locations?: any[];
	changeKeyword?: any;
	isMobile?: boolean;
}

export const useKeywordLocation = ({
	searchByReference,
	searchByProject,
	locations = [],
}: KeywordLocationProps) => {
	// hook setup
	const [keyword, setKeyWord] = useState<string>("");
	const [location, setLocation] = useState(null);
	const { filtersTags } = useFilters();
	const { changeFiltersAndUpdateURL: changeFilters } = useSearchResultsFilters();

	const [typing, setTyping] = useState(false);

	useEffect(() => updateFilters(), [location]);

	// hook functions
	const onSelect = (val, opt) => {
		setLocation(opt);
		setKeyWord(val);
	};

	const onSearch = (val: string) => {
		setTyping(true);
		if (isValidCharacters(val) || val == "") {
			setKeyWord(val);
			search(val);
		}
	};

	const [search] = useDebouncedCallback((val: string) => {
		if (val && val.length > 0) {
			searchQuery({ variables: { strSearch: val } }).then(() => {setTyping(false)});
		}
		if (searchByProject && val && val.length > 0) projectQuery({ variables: { title: val } }).then(() => {setTyping(false)});
		if (searchByReference && val && val.length >= 5) refQuery({ variables: { code: val } }).then(() => {setTyping(false)});
	}, 200);

	const [searchQuery, { data, loading }] = useLazyQuery(KEYWORD_LOCATION_QUERY);
	const [projectQuery, { data: dataProjects, loading: loadingProjects }] = useLazyQuery(
		PROJECTS_QUERY
	);

	const [refQuery, { data: dataRef, loading: loadingRef }] = useLazyQuery(REF_QUERY);

	const handleMultipleNeighbourhoods = (data) => {
		if (!filtersTags?.neighborhood_id || filtersTags?.neighborhood_id?.length === 0) { //no existe ni un barrio
			changeFilters({
				neighborhood_id: [{ value: data.id, text: data.name }],
				estate_id: { text: data.estate.name, value: data.estate.id },
			});
		} else {
			if (filtersTags?.estate_id?.value == data.estate.id) { //si ya existe el estado
				if (!filtersTags?.neighborhood_id.find(n => n.value === data.id)) {
					const newNeighborhoods = [...filtersTags?.neighborhood_id, { text: data.name, value: data.id }];

					changeFilters({
						neighborhood_id: newNeighborhoods,
					});
				}
			} else { //si es el primer barrio del estado
				changeFilters({
					neighborhood_id: [{ text: data.name, value: data.id }],
					estate_id: { text: data.estate.name, value: data.estate.id },
				});
			}
		}
	}

	const updateFilters = () => {
		if (location) {
			if (location.__typename == "keyword") {
				if (locations.length > 0) {
					const firstLocation = locations[0];

					if (firstLocation.__typename === "Neighborhood") {
						handleMultipleNeighbourhoods(firstLocation)
					} else {
						changeFilters({
							neighborhood_id: [],
							estate_id: { text: firstLocation.name, value: firstLocation.id },
							searchstring: null,
						});
					}
				} else {
					changeFilters({
						searchstring: { text: "Palabra Clave: " + keyword, value: keyword },
					});
				}
			} else if (location.__typename === 'Neighborhood') {
				handleMultipleNeighbourhoods(location)
			} else if (location.__typename === 'Estate') {
				changeFilters({
					neighborhood_id: [],
					estate_id: { text: location.name, value: location.id },
					searchstring: null,
				});
			}
		}
	};

	// hook output API
	return {
		keyword,
		setKeyWord,
		location,
		show: true,
		onSearch,
		onSelect,
		searchLocationQuery: searchQuery,
		searchResults: data,
		refResults: dataRef,
		projectsResults: dataProjects,
		searchLoading: keyword !== '' && (loading || loadingRef || loadingProjects || typing),
	};
};

